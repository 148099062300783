


import Component from 'vue-class-component';
import { Vue } from 'vue-property-decorator';
import SignIn from '@/_components/auth/sign-in/sign-in.vue';
import SignUp from '@/_components/auth/sign-up/sign-up.vue';
import { Getter } from 'vuex-class';

@Component({
  name: 'auth-page',
  components: {
    SignIn,
    SignUp,
  },
})
export default class AuthPage extends Vue {
  @Getter('authStore/isEnabledSignUp') isEnabledSignUp: boolean;
  @Getter('authStore/isAuthenticated') isAuthenticated: boolean;

  public created(): void {
    this.isAuthenticated ? this.hideAuthPopup() : this.showAuthPopup();
  }

  /* Hiding it so there are not two auths simultaneosly
   */
  public async hideAuthPopup(): Promise<void> {
    await this.$store.dispatch('authStore/setAuthPopupTargetRoute', null);
    await this.$store.dispatch('authStore/setShowStandaloneAuth', false);
  }

  public async showAuthPopup(): Promise<void> {
    await this.$store.dispatch('authStore/setAuthPopupTargetRoute', null);
    await this.$store.dispatch('authStore/setShowStandaloneAuth', true);
  }
}
